import React, {FC} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Field, useFormikContext} from "formik";
import {FormikRowCheckbox, FormikRowInput} from "@components/panel/formik";
import ReactQuill from "@components/panel/reactQuill";
import {FormFeedback} from "reactstrap";

interface BaseBlockFormProps {
    editForm?: boolean;
}

const BaseInfoBlock: FC<BaseBlockFormProps> = ({editForm}) => {

    const {
        values,
        setFieldValue,
        errors
    } = useFormikContext<{description: string}>();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Основное
            </Card.Header>
            <Card.Body>
                <Field
                    label='Название'
                    type='text'
                    name='name'
                    placeholder='Название позиции'
                    component={FormikRowInput}
                />
                <Form.Group as={Row} className="mb-3">
                    <Form.Label
                        column
                        htmlFor='description'
                        sm={3}>Описание</Form.Label>
                    <Col sm={9}>
                        <ReactQuill
                            defaultValue={values.description}
                            onChange={value => setFieldValue('description', value)} />
                        <FormFeedback>{errors.description}</FormFeedback>
                    </Col>
                </Form.Group>
                {editForm && (
                    <Field
                        label='Активно'
                        id='new-bonus-active'
                        name='isActive'
                        type='switch'
                        component={FormikRowCheckbox}
                    />
                )}
            </Card.Body>
        </Card>
    )
}

export default BaseInfoBlock;