import {FC, PropsWithChildren, useMemo} from "react";
import {useMarketCategories} from "@hooks/selectors/services.selectors";
import {injectContextArray} from "@components/constructor";
import {MarketCategory} from "@interfaces/user/market";
import {MarketCategoryProvider} from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface MarketCategoriesListChildProps {
    has_icon: boolean;
    market_category_url: string;
    market_category_loading: boolean;
    market_category_loaded: boolean;
    market_category_error: boolean;
    market_category_items_count: number;
}

const MarketCategoriesList: FC<PropsWithChildren> = ({children}) => {

    const { loading, loaded, error, categories } = useMarketCategories();

    const data = useMemo<InjectContextType<MarketCategory, MarketCategoriesListChildProps>[]>(() => categories.map((category) => {
        return {
            key: category.id,
            value: category,
            childData: {
                has_icon: Boolean(category.icon),
                market_category_url: category.shortName,
                market_category_error: Boolean(error),
                market_category_loaded: loaded,
                market_category_loading: loading,
                market_category_items_count: category?.items.length || 0
            }
        }
    }), [categories, error, loaded, loading]);

    return injectContextArray(data, MarketCategoryProvider, children);
}

export default MarketCategoriesList;