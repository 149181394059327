import {FC, useEffect, useRef, useState} from "react";
import {Navbar} from "react-bootstrap";
import classNames from "classnames";
import NavbarTopElements from "./navbarTopElements";
import {sidebarActions} from "@store/sidebarStore";
import {useDispatch} from "react-redux";
import {useSidebar} from "@hooks/selectors/panel.selectors";

const NavbarTop: FC = () => {

    const dispatch = useDispatch();
    const [showDropShadow, setShowDropShadow] = useState(false);
    const {
        showBurgerMenu
    } = useSidebar();

    const setDropShadow = () => {
        const el = document.documentElement;
        if (el.scrollTop > 0) {
            setShowDropShadow(true);
        } else {
            setShowDropShadow(false);
        }
    };

    const handleBurgerMenu = () => {
        //(navbarPosition === 'top' || navbarPosition === 'double-top') &&
        //setConfig('navbarCollapsed', !navbarCollapsed);
        //(navbarPosition === 'vertical' || navbarPosition === 'combo') &&
        //setConfig('showBurgerMenu', !showBurgerMenu);
        dispatch(sidebarActions.setBurgerMenu(!showBurgerMenu));
        //TODO доделать позиции меню
    };

    useEffect(() => {
        window.addEventListener('scroll', setDropShadow);
        return () => window.removeEventListener('scroll', setDropShadow);
    }, []);

    const burgerMenuRef = useRef<HTMLDivElement>(null);

    return (
        <Navbar className={classNames('navbar-glass fs-10 navbar-top sticky-kit', {
            // 'navbar-glass-shadow': showDropShadow
            'navbar-glass-shadow': showDropShadow// && !isChat
        })}
        expand={true}>
            <NavbarTopElements
                navbarCollapsed={false}
                navbarPosition={'vertical'}
                handleBurgerMenu={handleBurgerMenu}
                burgerMenuRef={burgerMenuRef}
            />
        </Navbar>
    )
}

export default NavbarTop;