import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import { KeyValueSet } from "@interfaces/helpers";
import { LoginServersListInfo, LoginServerNameInfo, LoginServerContextInfo, LoginServerChargeRateInfo } from "@constructor/components/loginServer";
import { Sidebar, SidebarCategoryList, SidebarCategoryItemList, SidebarCategoryIcon, SidebarItemIcon, SidebarCategoryName, SidebarItemName, SidebarCategoryBadge, SidebarItemBadge, SidebarCategoredItem } from "@constructor/components/panel/sidebar";
import { ProjectName } from "@constructor/components/panel/project";
import { Condition, RolesCondition } from "@constructor/components/Logic";
import { Page, PageName, PageTitle, Breadcrumbs } from "@constructor/components/panel/breadcrumbs";
import { CurrentYear } from "@constructor/components/panel/system";
import { TableSpinner } from "@components/panel";
import {
    MarketInfo,
    MarketItemsListInfo,
    MarketItemNameInfo,
    MarketItemDescriptionInfo,
    MarketItemPriceInfo,
    MarketItemDiscountPriceInfo,
    MarketItemIconInfo,
    MarketBuyItemFormInfo,
    MarketCategoriesListInfo,
    MarketCategoryNameInfo,
    MarketCategoryIconInfo,
    MarketCategoryDescriptionInfo,
    MarketItemLimitInfo
} from "@constructor/components/features/market";
import { DailyGift, DailyGiftDays, DailyGiftItemCount, DailyGiftName, DailyGiftReceive, DailyGiftsList, DailyGiftStats } from "@constructor/components/features/Daily";
import { NewsBlockInfo, NewsBodyInfo, NewsDateInfo, NewsListInfo, NewsTitleInfo } from "@constructor/components/features/news";
import { ModalContextInfo, ModalHeaderInfo, ModalInfo, ModalTogglerInfo, SimpleModalContextInfo } from "@constructor/components/modals";
import { AccountCreateTimeInfo, AccountListInfo, AccountNameInfo, ChangeAccountPasswordFormInfo, ChargeAccountCountInfo, ChargeAccountFormInfo, CreateAccountFormInfo } from "@constructor/components/core/accounts";
import { UserAvatarInfo, UserBalanceInfo, UserEmailInfo, UserLastAuthInfo, UserNameInfo, UserPublicNameInfo } from "@constructor/components/core/users";
import { BonusCodeInfo, BonusCodeLogsInfo, BonusCodeLogsListInfo, BonusCodeUseFormInfo, BonusCodeUseTimeInfo, BonusCodeValueInfo } from "@constructor/components/features/bonuses";
import { PaginationPagesInfo } from "@constructor/components/core/pagination";
import {
    SupportEditableMessageTextInfo,
    SupportMessageAvatarInfo,
    SupportMessageSenderNameInfo,
    SupportMessagesGroupListInfo,
    SupportMessagesListInfo,
    SupportMessageTextInfo,
    SupportMessageTimeInfo,
    SupportMessageTitleInfo
} from "@constructor/components/features/support/messages";
import {
    SupportMessageAttachmentInfo,
    SupportMessageAttachmentsListInfo
} from "@constructor/components/features/support/messages/attachments";
import { SupportInfo, SupportTopicCreateTimeInfo, SupportTopicLastAnswerInfo, SupportTopicInfo, SupportTopicNameInfo, SupportTopicUpdateTimeInfo, SupportTopicsListInfo, SupportTopicInputFormInfo, SupportTopicStatusInfo, CreateSupportTopicFormInfo } from "@constructor/components/features/support/topics";
import { SignInFormInfo, SignUpFormInfo, ResetPasswordFormInfo, ForgotPasswordFormInfo, ConfirmEmailBlockInfo, ConfirmEmailMessageInfo } from "@components/constructor/components/core/guest";
import { DonateFormInfo, DonateRateLoginsInfo, DonateRateGamesInfo } from "@constructor/components/features/donate";
import { DonateLogsInfo, DonateLogsListInfo, DonateLogItemSumInfo, DonateLogItemDateInfo, DonateLogItemIndexInfo } from "@constructor/components/features/donate/logs";
import { DonateBonusListInfo, DonateBonusThresholdInfo, DonateBonusValueInfo, DonateBonusesInfo } from "@constructor/components/features/donate/bonuses";
import { NotificationsInfo, NotificationsListInfo, NotificantionTitleInfo, NotificationMessageInfo, NotificationIconInfo, NotificationTimeInfo } from "@constructor/components/core/notifications";
import { QueryInfo, QueryMessageInfo, QueryNameInfo, QueryPriceInfo, QueryServerNameInfo, QueryTableBodyInfo, QueryTableHeadInfo } from "@constructor/components/features/queries";
import { AuthLogDateInfo, AuthLogIPInfo, AuthLogsInfo, AuthLogsListInfo } from "@constructor/components/core/logs/auth";
import { AvatarCropperInfo, AvatarUploaderInfo, ChangePasswordFormInfo, ChangeProfileFormInfo } from "@constructor/components/core/settings";
import { InventoryInfo, InventoryItemInfo, InventoryItemCountInfo, InventoryItemsListInfo, SendInventoryItemFormInfo } from "@constructor/components/features/inventory";
import { SliderInfo, SlidesInfo } from "@constructor/components/core/slider";
import { ComponentType } from "react";
import { ItemDescriptionInfo, ItemIconInfo, ItemInfo, ItemNameInfo } from "@constructor/components/features/items";
import { GameServerChargeRateInfo, GameServerNameInfo, GameServersListInfo } from "@constructor/components/core/servers/game"
import { GoalDescriptionInfo, GoalNameInfo, GoalsInfo, GoalsListInfo } from "@constructor/components/features/goals";
import { GoalRewardBalanceInfo, GoalRewardDescriptionInfo, GoalRewardInfo, GoalRewardItemCountInfo, GoalRewardNameInfo, GoalRewardsInfo, GoalRewardsListInfo } from "@constructor/components/features/goals/rewards";
import { RefCodeInfo, RefCodeLinkInfo } from "@constructor/components/features/refs";
import { RefStatNameInfo, RefStatReceiveTimeInfo, RefStatsInfo, RefStatsListInfo } from "@constructor/components/features/refs/stats";
import { BonusCodeBuyFormInfo, PersonalBonusCodeInfo, PersonalBonusCodesInfo, PersonalBonusCodesListInfo, PersonalBonusCodeUseTimeInfo, PersonalBonusCodeValueInfo } from "@constructor/components/features/bonuses/personal";
import {SupportTopicInputAttachedImagesListInfo} from "@constructor/components/features/support/topics/attachments";
import LightBox from "../../falcon/lightBox";


const SidebarInfo: ConstructionComponentInfo = {
    component: Sidebar,
    addedChildProps: [
        'sidebar_opened',
        'sidebar_right'
    ]
}

const SidebarCategoryListInfo: ConstructionComponentInfo = {
    component: SidebarCategoryList,
    requiredParent: [
        'Sidebar'
    ],
    addedChildProps: [
        'sidebar_category_active',
        'sidebar_category_opened',
        'sidebar_category_toggle',
        'sidebar_category_item_count'
    ]
}

const SidebarCategoryItemListInfo: ConstructionComponentInfo = {
    component: SidebarCategoryItemList,
    requiredParent: [
        'SidebarCategoryList'
    ],
    addedChildProps: [
        'sidebar_item_active',
        'sidebar_item_path'
    ]
}

const SidebarCategoryIconInfo: ConstructionComponentInfo = {
    component: SidebarCategoryIcon,
    requiredParent: [
        'SidebarCategoryList'
    ]
}

const SidebarItemIconInfo: ConstructionComponentInfo = {
    component: SidebarItemIcon,
    requiredParent: [
        'SidebarCategoryItemList'
    ]
}

const SidebarCategoryNameInfo: ConstructionComponentInfo = {
    component: SidebarCategoryName,
    requiredParent: [
        'SidebarCategoryList'
    ]
}

const SidebarItemNameInfo: ConstructionComponentInfo = {
    component: SidebarItemName,
    requiredParent: [
        'SidebarCategoryItemList'
    ]
}

const SidebarCategoryBadgeInfo: ConstructionComponentInfo = {
    component: SidebarCategoryBadge,
    requiredParent: [
        'SidebarCategoryList'
    ]
}

const SidebarItemBadgeInfo: ConstructionComponentInfo = {
    component: SidebarItemBadge,
    requiredParent: [
        'SidebarCategoryItemList'
    ]
}

const SidebarCategoredItemInfo: ConstructionComponentInfo = {
    component: SidebarCategoredItem,
    requiredParent: [
        'SidebarCategoryItemList'
    ],
    addedChildProps: [
        'sidebar_item_active',
        'sidebar_item_path'
    ]
}

const ProjectNameInfo: ConstructionComponentInfo = {
    component: ProjectName
}

const ConditionInfo: ConstructionComponentInfo = {
    component: Condition
}

const RolesConditionInfo: ConstructionComponentInfo = {
    component: RolesCondition
}

const PageInfo: ConstructionComponentInfo = {
    component: Page,
    injectChild: [
        'route_info'
    ]
}

const PageNameInfo: ConstructionComponentInfo = {
    component: PageName,
    requiredParent: [
        'Page'
    ]
}

const PageTitleInfo: ConstructionComponentInfo = {
    component: PageTitle,
    requiredParent: [
        'Page'
    ]
}

const BreadcrumbsInfo: ConstructionComponentInfo = {
    component: Breadcrumbs,
    requiredParent: [
        'Page'
    ],
    addedChildProps: [
        'crumb_idx',
        'crumb_name',
        'crumb_use_link',
        'crumb_link',
        'crumb_active'
    ]
}

const CurrentYearInfo: ConstructionComponentInfo = {
    component: CurrentYear
}

const TableSpinnerInfo: ConstructionComponentInfo = {
    component: TableSpinner
}

const DailyGiftInfo: ConstructionComponentInfo = {
    component: DailyGift,
    requiredParent: [
        'DailyGiftsList'
    ]
}

const DailyGiftNameInfo: ConstructionComponentInfo = {
    component: DailyGiftName,
    requiredParent: [
        'DailyGiftsList'
    ]
}

const DailyGiftItemCountInfo: ConstructionComponentInfo = {
    component: DailyGiftItemCount,
    requiredParent: [
        'DailyGift'
    ]
}

const DailyGiftDaysInfo: ConstructionComponentInfo = {
    component: DailyGiftDays,
    requiredParent: [
        'DailyGiftsList'
    ]
}

const DailyGiftsListInfo: ConstructionComponentInfo = {
    component: DailyGiftsList as ComponentType,
    injectChild: [
        'slider_ref'
    ]
}

const DailyGiftStatsInfo: ConstructionComponentInfo = {
    component: DailyGiftStats,
    injectChild: [
        'slider_ref'
    ]
}

const DailyGiftReceiveInfo: ConstructionComponentInfo = {
    component: DailyGiftReceive
}

const LightBoxInfo: ConstructionComponentInfo = {
    component: LightBox,
    props: [
        'image',
        'fileId'
    ]
}

const infos: KeyValueSet<ConstructionComponentInfo> = {

    DailyGiftsListInfo,
    DailyGiftInfo,
    DailyGiftNameInfo,
    DailyGiftItemCountInfo,
    DailyGiftDaysInfo,
    DailyGiftReceiveInfo,
    DailyGiftStatsInfo,

    SignInFormInfo,
    SignUpFormInfo,
    ResetPasswordFormInfo,
    ForgotPasswordFormInfo,
    ConfirmEmailBlockInfo,
    ConfirmEmailMessageInfo,

    DonateFormInfo,
    DonateRateLoginsInfo,
    DonateRateGamesInfo,
    DonateLogsInfo,
    DonateLogsListInfo,
    DonateLogItemSumInfo,
    DonateLogItemDateInfo,
    DonateLogItemIndexInfo,
    DonateBonusListInfo,
    DonateBonusThresholdInfo,
    DonateBonusValueInfo,
    DonateBonusesInfo,

    LoginServersListInfo,
    LoginServerContextInfo,
    LoginServerNameInfo,
    LoginServerChargeRateInfo,

    GameServerNameInfo,
    GameServersListInfo,
    GameServerChargeRateInfo,

    AccountListInfo,
    AccountNameInfo,
    AccountCreateTimeInfo,

    CreateAccountFormInfo,
    ChangeAccountPasswordFormInfo,
    ChargeAccountFormInfo,
    ChargeAccountCountInfo,

    SidebarInfo,
    SidebarCategoryListInfo,
    SidebarCategoryItemListInfo,
    SidebarCategoryIconInfo,
    SidebarItemIconInfo,
    SidebarCategoryNameInfo,
    SidebarItemNameInfo,
    SidebarCategoryBadgeInfo,
    SidebarItemBadgeInfo,
    SidebarCategoredItemInfo,

    UserNameInfo,
    UserEmailInfo,
    UserBalanceInfo,
    UserPublicNameInfo,
    UserAvatarInfo,
    UserLastAuthInfo,

    ProjectNameInfo,

    ConditionInfo,
    RolesConditionInfo,

    PageInfo,
    PageNameInfo,
    PageTitleInfo,
    BreadcrumbsInfo,

    NotificationsInfo,
    NotificationsListInfo,
    NotificantionTitleInfo,
    NotificationMessageInfo,
    NotificationIconInfo,
    NotificationTimeInfo,

    CurrentYearInfo,
    TableSpinnerInfo,

    MarketInfo,
    MarketCategoriesListInfo,
    MarketCategoryNameInfo,
    MarketCategoryIconInfo,
    MarketCategoryDescriptionInfo,
    MarketItemsListInfo,
    MarketItemNameInfo,
    MarketItemDescriptionInfo,
    MarketItemPriceInfo,
    MarketItemDiscountPriceInfo,
    MarketItemIconInfo,
    MarketItemLimitInfo,
    MarketBuyItemFormInfo,

    InventoryInfo,
    InventoryItemInfo,
    InventoryItemCountInfo,
    InventoryItemsListInfo,
    SendInventoryItemFormInfo,

    ItemInfo,
    ItemNameInfo,
    ItemDescriptionInfo,
    ItemIconInfo,

    NewsBlockInfo,
    NewsListInfo,
    NewsTitleInfo,
    NewsBodyInfo,
    NewsDateInfo,

    ModalContextInfo,
    ModalInfo,
    ModalHeaderInfo,
    SimpleModalContextInfo,
    ModalTogglerInfo,

    BonusCodeInfo,
    BonusCodeLogsInfo,
    BonusCodeLogsListInfo,
    BonusCodeUseTimeInfo,
    BonusCodeValueInfo,
    BonusCodeUseFormInfo,

    BonusCodeBuyFormInfo,
    PersonalBonusCodeInfo,
    PersonalBonusCodesInfo,
    PersonalBonusCodesListInfo,
    PersonalBonusCodeUseTimeInfo,
    PersonalBonusCodeValueInfo,

    PaginationPagesInfo,

    SupportInfo,
    SupportTopicCreateTimeInfo,
    SupportTopicInputFormInfo,
    SupportTopicInputAttachedImagesListInfo,
    SupportTopicLastAnswerInfo,
    SupportTopicInfo,
    SupportTopicNameInfo,
    SupportTopicStatusInfo,
    SupportTopicUpdateTimeInfo,
    SupportTopicsListInfo,
    SupportMessageAvatarInfo,
    SupportMessagesGroupListInfo,
    SupportMessageSenderNameInfo,
    SupportMessagesListInfo,
    SupportMessageTextInfo,
    SupportMessageTimeInfo,
    SupportMessageTitleInfo,
    SupportMessageAttachmentsListInfo,
    SupportMessageAttachmentInfo,
    SupportEditableMessageTextInfo,
    CreateSupportTopicFormInfo,

    QueryInfo,
    QueryMessageInfo,
    QueryNameInfo,
    QueryPriceInfo,
    QueryServerNameInfo,
    QueryTableBodyInfo,
    QueryTableHeadInfo,

    AuthLogDateInfo,
    AuthLogIPInfo,
    AuthLogsInfo,
    AuthLogsListInfo,

    ChangePasswordFormInfo,
    ChangeProfileFormInfo,
    AvatarUploaderInfo,
    AvatarCropperInfo,

    SliderInfo,
    SlidesInfo,

    GoalDescriptionInfo,
    GoalNameInfo,
    GoalsInfo,
    GoalsListInfo,

    GoalRewardBalanceInfo,
    GoalRewardDescriptionInfo,
    GoalRewardInfo,
    GoalRewardItemCountInfo,
    GoalRewardNameInfo,
    GoalRewardsInfo,
    GoalRewardsListInfo,

    RefCodeInfo,
    RefCodeLinkInfo,

    RefStatsInfo,
    RefStatNameInfo,
    RefStatReceiveTimeInfo,
    RefStatsListInfo,

    LightBoxInfo
}


export default infos;